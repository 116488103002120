@font-face {
  font-family: "gtAmericaRegular";
  src: local("gtAmericaRegular"),
    url("../public/fonts/gtAmericaRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "gtAmericaMedium";
  src: local("gtAmericaMedium"),
    url("../public/fonts/gtAmericaMedium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "gtAmericaBold";
  src: local("gtAmericaBold"),
    url("../public/fonts/gtAmericaBold.woff") format("woff");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "gtAmericaBoldItalic";
  src: local("gtAmericaBoldItalic"),
    url("../public/fonts/gtAmericaBoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "kineticaLight";
  src: local("kineticaLight"),
    url("../public/fonts/kineticaLight.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "kineticaBold";
  src: local("kineticaBold"),
    url("../public/fonts/kineticaBold.otf") format("opentype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}
@font-face {
  font-family: "HalvarBreitRegular";
  src: url("../public/fonts/HalvarBreit-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HalvarBreitMedium";
  src: url("../public/fonts/HalvarBreit-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HalvarBreitBold";
  src: url("../public/fonts/HalvarBreit-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}
@font-face {
  font-family: "HalvarBreitExtraBold";
  src: url("../public/fonts/HalvarBreit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: bold;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scroll Bar Style */
::-webkit-scrollbar {
  width: 5px;
  height: 4px;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-color: #3a3a3a;
  border-radius: 20px;
}

.typeformSection {
  border: 30px solid;
  border-image: url(../public/typeformBgWindow.svg) 30 round;
  border-image-slice: 70;
  width: auto;
}

@media (min-width: 640px) {
  .typeformSection {
    border: 40px solid;
    border-image: url(../public/typeformBgWindow.svg) 40 round;
    border-image-slice: 80;
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .typeformSection {
    width: 70%;
  }
}

@media (min-width: 1280px) {
  .typeformSection {
    border: 60px solid;
    border-image: url(../public/typeformBgWindow.svg) 40 round;
    border-image-slice: 80 80 80 80;
    width: fit-content;
  }
}

.react-multi-carousel-dot button {
  border-color: #000000 !important;
}

.react-multi-carousel-dot--active button {
  background-color: #ff00ff !important;
  box-shadow: 1px 1px 0px #9b9b9b;
}

.pricing-slide {
  padding: 30px 0 2.5rem 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
  background-image: url(./images/nextArrow.webp) !important;
  background-size: 100% auto !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  content: "" !important;
  transform: scaleX(-1);
}
.swiper-button-next {
  right: 0 !important;
  background-image: url(./images/nextArrow.webp) !important;
  background-size: 100% auto !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  content: "" !important;
}

.swiper-button-prev::after {
  display: none !important;
  content: "" !important;
}

.swiper-button-next::after {
  display: none !important;
  content: "" !important;
}

.swiper-button-prev:after,
.swiper-rtl,
.swiper-button-next:after {
  display: none !important;
  content: "" !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  border: 2px solid #000000 !important;
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  width: 10px !important;
  height: 10px !important;
  border: 2px solid #000000 !important;
  background-color: #ff00ff !important;
}

@media (min-width: 640px) {
  .pricing-slide {
    padding: 30px 2rem 2.5rem 2rem !important;
  }
}

/* Styles for Ticker Animation for Brands Section */
.ticker__list {
  animation: ticker 15s infinite linear;
}
.ticker:hover .ticker__list {
  animation-play-state: paused;
}

@keyframes ticker {
  100% {
    transform: translateX(-100%);
  }
}
